import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./components/App";
import Loadable from "react-loadable";
import Loading from "./components/helpers/loading";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import withTracker from "./withTracker";
import Home from "./components/Mobile/Home";
import Search from "./components/Mobile/Search";
import KeepAlive from "react-activation";
import { AliveScope } from "react-activation";
import Privacy, { PrivacyPolicy } from "./components/Mobile/Profile/Privacy";
import { TermsCondition } from "./components/Mobile/Profile/TermsCondition";
import { RefundPolicy } from "./components/Mobile/Profile/RefundPolicy";
import { DeliveryPolicy } from "./components/Mobile/Profile/DeliveryPolicy";

const NotFound = Loadable({
  loader: () => import("./components/NotFound"),
  loading: () => <Loading />,
});

const HomeKeepAlivee = () => {
  return (
    <KeepAlive>
      <Home />
    </KeepAlive>
  );
};

const SearchKeepAlive = () => {
  return (
    <KeepAlive>
      <Search />
    </KeepAlive>
  );
};

const ProfileKeepAlive = () => {
  return (
    <KeepAlive>
      <Profile />
    </KeepAlive>
  );
};
const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

// Store Owner
const StoreLogin = Loadable({
  loader: () => import("./components/Store/Login"),
  loading: () => <Loading />,
});

const StoreRegister = Loadable({
  loader: () => import("./components/Store/Register"),
  loading: () => <Loading />,
});

const StoreAccount = Loadable({
  loader: () => import("./components/Store/Dashboard/Account"),
  loading: () => <Loading />,
});

const StoreDashboard = Loadable({
  loader: () => import("./components/Store"),
  loading: () => <Loading />,
});

const StoreReports = Loadable({
  loader: () => import("./components/Store/Dashboard/Reports"),
  loading: () => <Loading />,
});

const StoreOrders = Loadable({
  loader: () => import("./components/Store/Dashboard/Orders"),
  loading: () => <Loading />,
});

const StorePayout = Loadable({
  loader: () => import("./components/Store/Dashboard/Payout"),
  loading: () => <Loading />,
});

const CompletedOrders = Loadable({
  loader: () => import("./components/Store/Dashboard/CompletedOrders"),
  loading: () => <Loading />,
});

const StoreProducts = Loadable({
  loader: () => import("./components/Store/Dashboard/Products"),
  loading: () => <Loading />,
});

const StoreViewProducts = Loadable({
  loader: () => import("./components/Store/Dashboard/Products/View"),
  loading: () => <Loading />,
});

const StoreEdit = Loadable({
  loader: () => import("./components/Store/Dashboard/Account/StoreEdit"),
  loading: () => <Loading />,
});

const UserEdit = Loadable({
  loader: () => import("./components/Store/Dashboard/Account/UserEdit"),
  loading: () => <Loading />,
});

const AddItem = Loadable({
  loader: () => import("./components/Store/Dashboard/Products/NewItem"),
  loading: () => <Loading />,
});

const AddItemCategory = Loadable({
  loader: () => import("./components/Store/Dashboard/Products/NewItemCategory"),
  loading: () => <Loading />,
});

const AddAddon = Loadable({
  loader: () => import("./components/Store/Dashboard/Products/NewAddon"),
  loading: () => <Loading />,
});

const AddAddonCategory = Loadable({
  loader: () =>
    import("./components/Store/Dashboard/Products/NewAddonCategory"),
  loading: () => <Loading />,
});

const OrderView = Loadable({
  loader: () => import("./components/Store/Dashboard/Orders/OrderView"),
  loading: () => <Loading />,
});

const RatingAndReview = Loadable({
  loader: () => import("./components/Mobile/RatingAndReview"),
  loading: () => <Loading />,
});
const SingleBrand = Loadable({
  loader: () => import("./components/Mobile/SinlgleBrand"),
  loading: () => <Loading />,
});
const WalletUser = Loadable({
  loader: () => import("./components/Mobile/Profile/Wallet"),
  loading: () => <Loading />,
});
const COrderView = Loadable({
  loader: () => import("./components/Mobile/MyOrders/OrderView"),
  loading: () => <Loading />,
});
const TrackOrder = Loadable({
  loader: () => import("./components/Mobile/MyOrders/TrackOrder"),
  loading: () => <Loading />,
});
const MyOrders = Loadable({
  loader: () => import("./components/Mobile/MyOrders"),
  loading: () => <Loading />,
});
const GeoLocationPage = Loadable({
  loader: () => import("./components/Mobile/GeoLocationPage"),
  loading: () => <Loading />,
});
const Location = Loadable({
  loader: () => import("./components/Mobile/Location"),
  loading: () => <Loading />,
});
const Profile = Loadable({
  loader: () => import("./components/Mobile/Profile"),
  loading: () => <Loading />,
});
const About = Loadable({
  loader: () => import("./components/Mobile/About"),
  loading: () => <Loading />,
});
const Cart = Loadable({
  loader: () => import("./components/Mobile/Cart"),
  loading: () => <Loading />,
});

const CouponList = Loadable({
  loader: () => import("./components/Mobile/Cart/CouponBlock/CouponList"),
  loading: () => <Loading />,
});

const Loyalty = Loadable({
  loader: () => import("./components/Mobile/Loyalty"),
  loading: () => <Loading />,
});
const Membership = Loadable({
  loader: () => import("./components/Mobile/Membership"),
  loading: () => <Loading />,
});

const MembershipSuccess = Loadable({
  loader: () => import("./components/Mobile/Membership/MembershipSuccess"),
  loading: () => <Loading />,
});

const SingleItem = Loadable({
  loader: () => import("./components/Mobile/SingleItem"),
  loading: () => <Loading />,
});
const SingleStore = Loadable({
  loader: () => import("./components/Mobile/SingleStore"),
  loading: () => <Loading />,
});
const BannerItems = Loadable({
  loader: () => import("./components/Mobile/BannerItems"),
  loading: () => <Loading />,
});
const SliderStores = Loadable({
  loader: () => import("./components/Mobile/SliderStores"),
  loading: () => <Loading />,
});
const ItemGroups = Loadable({
  loader: () => import("./components/Mobile/ItemGroups"),
  loading: () => <Loading />,
});
const Offers = Loadable({
  loader: () => import("./components/Mobile/Offers"),
  loading: () => <Loading />,
});

const AllPopularItems = Loadable({
  loader: () => import("./components/Mobile/Home/PopularItems/AllPopularItems"),
  loading: () => <Loading />,
});
// const Home = Loadable({
// 	loader: () => import("./components/Mobile/Home"),
// 	loading: () => <Loading />,
// });
const Login = Loadable({
  loader: () => import("./components/Mobile/Login"),
  loading: () => <Loading />,
});
const Delivery = Loadable({
  loader: () => import("./components/Delivery"),
  loading: () => <Loading />,
});
const Online = Loadable({
  loader: () => import("./components/Online"),
  loading: () => <Loading />,
});
const ContactUs = Loadable({
  loader: () => import("./components/Mobile/ContactUs"),
  loading: () => <Loading />,
});
// const Search = Loadable({
// 	loader: () => import("./components/Mobile/Search"),
// 	loading: () => <Loading />,
// });

ReactDOM.render(
  <Root>
    <BrowserRouter>
      <AliveScope>
        <React.Fragment>
          <Route component={ScrollToTop} />
          <Switch>
            {/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}
            <Route path={"/"} exact component={withTracker(App)} />
            <Route path={"/delivery"} exact component={Delivery} />
            <Route path={"/online"} exact component={Online} />

            {/* Customer Routes*/}
            <Route path={"/search-location"} exact component={Location} />
            <Route path={"/my-location"} exact component={GeoLocationPage} />
            <Route path={"/login"} exact component={Login} />
            <Route path={"/home"} exact component={HomeKeepAlivee} />
            <Route path={"/wallet-user"} exact component={WalletUser} />
             <Route path={"/privacy-policy"} exact component={PrivacyPolicy} />
           <Route path={"/refund-conditions"} exact component={RefundPolicy} />
           <Route path={"/delivery-policy"} exact component={DeliveryPolicy} />
            <Route path={"/terms-conditions"} exact component={TermsCondition} />
            <Route path={"/offers"} exact component={Offers} />
            <Route path={"/item-group/:id"} exact component={ItemGroups} />
            <Route path={"/slider-stores/:id"} exact component={SliderStores} />
            <Route path={"/banner-items/:id"} exact component={BannerItems} />
            <Route path={"/single-store/:id"} exact component={SingleStore} />
            <Route path={"/single-brand/:id"} exact component={SingleBrand} />
            <Route path={"/single-item/:id"} exact component={SingleItem} />
            <Route path={"/loyalty"} exact component={Loyalty} />
            <Route path={"/membership"} exact component={Membership} />
            <Route
              path={"/membership-success/:id"}
              exact
              component={MembershipSuccess}
            />
            <Route path={"/search"} exact component={SearchKeepAlive} />
            <Route path={"/cart"} exact component={Cart} />
            <Route path={"/coupon-list"} exact component={CouponList} />
            {/* <Route path={"/profile"} exact component={ProfileKeepAlive} /> */}
            <Route path={"/profile"} exact component={Profile} />
            <Route path={"/about"} exact component={About} />
            <Route path={"/my-orders"} exact component={MyOrders} />
            <Route
              path={"/order-view/:id"}
              exact
              component={COrderView}
            />
            <Route
              path={"/track-order/:id"}
              exact
              component={TrackOrder}
            />
            <Route path={"/contact-us"} exact component={ContactUs} />
            <Route
              path={"/all-popular-items"}
              exact
              component={AllPopularItems}
            />

            <Route
              path={"/rate-order/:id"}
              exact
              component={withTracker(RatingAndReview)}
            />
            {/* Customer Routes End*/}

            {/* Restaurant Owner Routes*/}
            <Route path={"/store/login"} exact component={StoreLogin} />
            <Route path={"/store/register"} exact component={StoreRegister} />
            <Route path={"/store/dashboard"} exact component={StoreDashboard} />
            <Route path={"/store/orders/:type"} exact component={StoreOrders} />
            <Route
              path={"/store/order/view/:unique_order_id"}
              exact
              component={OrderView}
            />
            <Route path={"/store/products"} exact component={StoreProducts} />
            <Route
              path="/store/view/product/:product_id"
              component={StoreViewProducts}
            />
            <Route path="/store/edit-user" component={UserEdit} />
            <Route path="/store/edit/:id" component={StoreEdit} />
            <Route path="/store/add-item" component={AddItem} />
            <Route
              path="/store/add-item-category"
              component={AddItemCategory}
            />
            <Route path="/store/add-addon" component={AddAddon} />
            <Route
              path="/store/add-addon-category"
              component={AddAddonCategory}
            />
            <Route path="/store/account" component={StoreAccount} />
            <Route path="/store/reports" component={StoreReports} />
            <Route path="/store/payout" component={StorePayout} />
            <Route path="/store/completed-orders" component={CompletedOrders} />
            {/* Restaurant Owner Routes End*/}

            {/* Common Routes */}
            <Route component={NotFound} />
          </Switch>
        </React.Fragment>
      </AliveScope>
    </BrowserRouter>
  </Root>,
  document.getElementById("root")
);
