import React, { Component } from "react";
import { connect } from "react-redux";
import { Spring, animated } from "react-spring";
import BackButtonHeader from "../../../Elements/BackButtonHeader";
import { getUserWallet } from "../../../../services/User/action";
import moment from "moment";
import Loading from "../../../helpers/loading-blue";

export class DeliveryPolicy extends Component {
  state = {
    loading: true,
  };

  checkDate = (i) => {
    const transactions = this.props.wallet.walletTransactions;
    if (transactions[i] && transactions[i - 1]) {
      let date = new Date(transactions[i].created_at).toDateString();

      let prevdate = new Date(transactions[i - 1].created_at).toDateString();
      if (date === prevdate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  render() {
    const { wallet } = this.props;

    return (
      // <Spring
      //     from={{ opacity: 0, marginLeft: '500px' }}
      //     to={{ opacity: 1, marginLeft: '0px' }}
      // // config={{ duration: 1000 }}
      // >
      //     {styles => (
      //         <animated.div style={styles}>
      <React.Fragment>
        <BackButtonHeader title={"Delivery Policy"} />
        <React.Fragment>
          <div
            className="mx-3"
            style={{
              marginTop: "7rem",
              marginBottom: "7rem",
              padding: "0px 10px",
            }}
          >
            <h1>Delivery Policy</h1>
            <h4 style={{ marginTop: "1rem" }}>Delivery Timelines</h4>
            <p>
              At Superjoe, we strive to deliver your favorite ice creams promptly to ensure the best quality and experience. Our estimated delivery time varies based on the location of the Buyer and is typically between 15 to 45 minutes from the time the Order is placed. Delivery timelines are subject to factors such as weather, traffic, and unforeseen operational delays.
            </p>

            <h4 style={{ marginTop: "1rem" }}>Delivery Address</h4>
            <p>
              The Buyer must provide an accurate and complete delivery address while placing the Order. Superjoe reserves the right to cancel the Order if the address provided is incorrect, incomplete, or outside the delivery zone. If we are unable to contact the Buyer using the phone number or email provided, the Order will be canceled, and any applicable refund policies will apply as per the Refund Policy.
            </p>

            <h4 style={{ marginTop: "1rem" }}>Condition of Delivered Items</h4>
            <p>
              Superjoe ensures that all ice cream deliveries are handled with utmost care. If the packaging of an item is found to be tampered with or damaged upon delivery, the Buyer has the right to refuse the delivery and must immediately inform Superjoe Customer Care through the Platform. In such cases, a replacement or refund will be provided as per the Refund Policy.
            </p>

            <h4 style={{ marginTop: "1rem" }}>Failed Delivery</h4>
            <p>
              In the event of a failed delivery due to the following reasons, Superjoe reserves the right to retain the full Order amount as a penalty:
              <ul>
                <li>The Buyer provides an incorrect or incomplete delivery address.</li>
                <li>The Buyer is unavailable or unreachable at the time of delivery.</li>
                <li>The Buyer fails to provide authorization or guidance for the delivery.</li>
              </ul>
              However, in the case of delivery failure due to reasons attributable to Superjoe or its delivery personnel (PDP), the Buyer will be entitled to a full refund or redelivery of the Order as per the Refund Policy.
            </p>

            <h4 style={{ marginTop: "1rem" }}>Contactless Delivery</h4>
            <p>
              Superjoe offers contactless delivery for the convenience and safety of Buyers. The Buyer can request contactless delivery by mentioning it in the delivery instructions while placing the Order. Our delivery personnel (PDP) will ensure the Order is left at the designated location specified by the Buyer.
            </p>

            <h4 style={{ marginTop: "1rem" }}>Force Majeure</h4>
            <p>
              Superjoe shall not be held liable for delays or failures in delivery caused by events beyond our reasonable control, including but not limited to natural disasters, accidents, strikes, or government restrictions. In such cases, the Buyer will be notified promptly, and a suitable resolution will be provided based on the situation.
            </p>
          </div>

        </React.Fragment>
      </React.Fragment>

      //         </animated.div>
      //     )
      //     }
      // </Spring>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  wallet: state.user.wallet,
});
